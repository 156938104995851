// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-dial-bore-gauge-for-line-boring-machine-tsx": () => import("./../../../src/pages/products/dial-bore-gauge-for-line-boring-machine.tsx" /* webpackChunkName: "component---src-pages-products-dial-bore-gauge-for-line-boring-machine-tsx" */),
  "component---src-pages-products-micrometric-device-for-knife-pull-out-tsx": () => import("./../../../src/pages/products/micrometric-device-for-knife-pull-out.tsx" /* webpackChunkName: "component---src-pages-products-micrometric-device-for-knife-pull-out-tsx" */),
  "component---src-pages-products-spindle-centering-device-tsx": () => import("./../../../src/pages/products/spindle-centering-device.tsx" /* webpackChunkName: "component---src-pages-products-spindle-centering-device-tsx" */),
  "component---src-pages-produkty-centrownik-wrzeciona-tsx": () => import("./../../../src/pages/produkty/centrownik-wrzeciona.tsx" /* webpackChunkName: "component---src-pages-produkty-centrownik-wrzeciona-tsx" */),
  "component---src-pages-produkty-mikrometryczny-przyrzad-do-wysuwania-noza-tsx": () => import("./../../../src/pages/produkty/mikrometryczny-przyrzad-do-wysuwania-noza.tsx" /* webpackChunkName: "component---src-pages-produkty-mikrometryczny-przyrzad-do-wysuwania-noza-tsx" */),
  "component---src-pages-produkty-srednicowka-do-wytaczarki-przenosnej-mobilnej-tsx": () => import("./../../../src/pages/produkty/srednicowka-do-wytaczarki-przenosnej-mobilnej.tsx" /* webpackChunkName: "component---src-pages-produkty-srednicowka-do-wytaczarki-przenosnej-mobilnej-tsx" */),
  "component---src-pages-wytaczarka-mobilna-przenosna-tsx": () => import("./../../../src/pages/wytaczarka-mobilna-przenosna.tsx" /* webpackChunkName: "component---src-pages-wytaczarka-mobilna-przenosna-tsx" */)
}

